<template lang="pug">
    v-container(fluid)
        v-card
            v-card-text
                div Cancel buy
                p.display-1.text--primary
                    | You cancelled your purchase
                .text--primary
                    | We invite you to search our other content
            v-card-actions
                v-btn(@click='goSearch') OK
</template>

<script>
  export default {
    methods: {
      goSearch () {
        const vm = this
        vm.$router.push('/search')
      },
    },
  }
</script>
